import { reduce } from 'lodash'
import type { IServerLDFlagSet } from 'types/launchdarkly-node-server-sdk'
import type { IClientLDFlagSet } from 'types/launchdarkly-js-client-sdk'

/**
 * @description This file defines which LaunchDarkly flags the client should listen to.
 *
 * Flag Management:
 * 1. Adding a new flag:
 *    - Create the flag in LaunchDarkly dashboard
 *    - Add the flag key to the `flags` array below (in its original form as known to LaunchDarkly)
 *    - Run `pnpm ld-codegen` to regenerate types
 *
 * 2. Removing a flag:
 *    - Remove the flag key from the `flags` array below
 *    - Run `pnpm ld-codegen` to regenerate types
 *    - Run `pnpm ts` to verify there is no remaining references to the flag in the codebase
 *
 * Note: Flag keys must be in their original form as known to LaunchDarkly (not camelCased).
 * Run `pnpm ld-codegen:verbose` to see detailed information about flag usage and potential issues.
 */
export const flags = reduce<keyof IServerLDFlagSet, IServerLDFlagSet>(
  [
    'A2X',
    'accounts-receivable',
    'adjustment-log-report',
    'calendly',
    'cin-7-visibility',
    'default-fulfillment-location-for-shopify',
    'deferral-request',
    'demand-forecasting',
    'disable-channel-forecast-break-down',
    'disable-xero-2-way-sync',
    'discrepancy-threshold-rules',
    'hide-2-way-xero-sync',
    'hide-xero',
    'ignore-landed-costs-upsell',
    'import-purchase-orders-from-qbo',
    'lockbox-reporting',
    'lots-on-stock-screen',
    'manually_push_skus_to_wms',
    'matching-feedback-collection',
    'more-prominent-data-import',
    'mui-theme',
    'navattic-product-tour',
    'on-demand-v2-v3-pricing-migration',
    'outbound-orders-v-1',
    'partial-external-payments',
    'payments-page-backfill-epoch',
    'payment-remittance-info',
    'po-line-items-refactor',
    'show-linked-documents-discrepancies-counter',
    'sync-receivable-invoices-qbo',
    'trackstar-sandbox-integration',
    'use-shopify-app-store-connect-link',
    'vendor-financing',
    'vendor-uom',
    'vendor-export',
    'po-transition-to-new-layout',
    'change-plan-survey-dialog',
    'catalog-inventory-tab',
    'grn-transition-to-new-layout',
  ],
  (flags, flag) => ({ ...flags, [flag]: undefined }),
  {},
) as IClientLDFlagSet
