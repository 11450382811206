import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { AppCacheProvider, type EmotionCacheProviderProps } from '@mui/material-nextjs/v14-pagesRouter'
import { createEmotionCache } from 'lib/createEmotionCache'
import { useMUITheme } from './hooks/useMUITheme/useMUITheme'

export type TAppStylesProviderProps = React.PropsWithChildren<EmotionCacheProviderProps>

const clientEmotionCache = createEmotionCache()

export const AppStylesProvider: React.FC<TAppStylesProviderProps> = ({
  emotionCache = clientEmotionCache,
  children,
}) => {
  const theme = useMUITheme()

  return (
    <AppCacheProvider emotionCache={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </AppCacheProvider>
  )
}
