import type { Theme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { theme, prideMonthTheme } from 'settle-ui/theme'

export const useMUITheme = (): Theme => {
  const { muiTheme } = useFlags()

  return useMemo(() => (muiTheme === 'pride-month' ? prideMonthTheme : theme), [muiTheme])
}
