import { ApolloProvider, type NormalizedCacheObject } from '@apollo/client'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { LicenseInfo } from '@mui/x-license'
import { Snackbars } from 'settle-ui/components/Snackbars/Snackbars'
import { AppProgress } from 'routes/components/AppProgress/AppProgress'
import { UserSettingsProvider } from 'routes/components/AppLayout/providers/UserSettingsProvider/UserSettingsProvider'
import { withLDProvider } from 'lib/withLDProvider/withLDProvider'
import { isTrackingDisabled } from 'lib/tracking/segment'
import { qsSchema } from 'lib/zod'
import { initDatadog } from 'lib/tracking/datadog'
import { setScrollbarStrategy } from './helpers/setScrollbarStrategy/setScrollbarStrategy'
import { useSetProfileIdCookie } from './hooks/useSetProfileIdCookie/useSetProfileIdCookie'
import { useLogSnackbarEvent } from './hooks/useLogSnackbarEvent/useLogSnackbarEvent'
import { useGlobalLDFlagsGetter } from './hooks/useGlobalLDFlagsGetter/useGlobalLDFlagsGetter'
import { AppStylesProvider, type TAppStylesProviderProps } from './components/AppStylesProvider/AppStylesProvider'
import { BusinessIdProvider } from './components/BusinessIdProvider/BusinessIdProvider'
import { SegmentIdentityTracking } from './components/SegmentIdentityTracking/SegmentIdentityTracking'
import { DatadogIdentityTracking } from './components/DatadogIdentityTracking/DatadogIdentityTracking'
import { GlobalStyles } from './components/GlobalStyles/GlobalStyles'
import * as events from './AppPage.events'
import { useInitializedApolloClient } from './hooks/useInitializedApolloClient/useInitializedApolloClient'
import { useTrackRouterTimings } from './hooks/useTrackRouterTimings/useTrackRouterTimings'
import { VercelAnalytics } from './components/VercelAnalytics/VercelAnalytics'

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY || '')

export type TAppPageProps = TAppStylesProviderProps &
  AppProps<{
    businessId: Maybe<string>
    profileId: Maybe<string>
    initialCache?: NormalizedCacheObject
  }>

export const AppPage = withLDProvider<React.JSX.IntrinsicAttributes & TAppPageProps>(
  ({ Component, pageProps, router, emotionCache }) => {
    const { profileId, initialCache, businessId: ssrBusinessId = null } = pageProps
    const businessId = qsSchema.parse(router.query.businessId) || ssrBusinessId
    const identifyUserInSegment = !isTrackingDisabled() && profileId
    const apolloClient = useInitializedApolloClient({ businessId, initialCache })

    useSetProfileIdCookie(profileId)
    useGlobalLDFlagsGetter()
    useTrackRouterTimings()
    useEffect(initDatadog, [])
    useEffect(setScrollbarStrategy, [])

    const logSnackbarDisplayed = useLogSnackbarEvent(router, events.SNACKBAR_DISPLAYED)
    const logSnackbarClicked = useLogSnackbarEvent(router, events.SNACKBAR_ACTION_BTN_CLICK)

    return (
      <>
        <Head>
          <title>Settle</title>
        </Head>
        <VercelAnalytics />
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RecoilRoot>
              <UserSettingsProvider>
                <AppStylesProvider emotionCache={emotionCache}>
                  <BusinessIdProvider businessId={businessId}>
                    {identifyUserInSegment && <SegmentIdentityTracking profileId={profileId} />}
                    {profileId && <DatadogIdentityTracking profileId={profileId} />}
                    <GlobalStyles />
                    <Snackbars onMount={void logSnackbarDisplayed} onActionClick={void logSnackbarClicked} />
                    <AppProgress />
                    <Component {...pageProps} />
                  </BusinessIdProvider>
                </AppStylesProvider>
              </UserSettingsProvider>
            </RecoilRoot>
          </LocalizationProvider>
        </ApolloProvider>
      </>
    )
  },
)
