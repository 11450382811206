import type { GridColDef } from '@mui/x-data-grid-premium'
import type { TDensity } from './helpers/createDensityStore/createDensityStore'

export const ROW_HEIGHT: Record<TDensity, number> = { default: 40, relaxed: 48 }
export const HEADER_HEIGHT: Record<TDensity, number> = { default: 32, relaxed: 40 }

export const suiGridClasses = {
  borderRight: 'border-right',
  borderLeft: 'border-left',
  numericTabularNums: 'numeric-tabular-nums',
  bold: 'bold',
  secondary: 'secondary',
  position: 'relative',
  rightAdornment: 'right-adornment',
  activeRow: 'active-row',
  hasSelectedRowsClass: 'hasSelectedRows',
}

export const moneyColumnDef: Partial<GridColDef> = {
  minWidth: 120,
  align: 'right' as const,
  cellClassName: suiGridClasses.numericTabularNums,
}

export const dateColumnDef: Partial<GridColDef> = {
  minWidth: 112,
  cellClassName: suiGridClasses.numericTabularNums,
}

export const quantityColumnDef: Partial<GridColDef> = {
  minWidth: 82,
  align: 'right' as const,
  cellClassName: suiGridClasses.numericTabularNums,
}
