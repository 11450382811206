import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { classNames } from 'settle-ui/theme'

export const GlobalStyles: React.FC = () => (
  <MuiGlobalStyles
    styles={({ breakpoints }) => ({
      body: {
        WebkitFontSmoothing: 'antialiased',
        textRendering: 'optimizelegibility',
      },
      '.pac-container': {
        zIndex: 10000,
      },
      '.MuiAutocomplete-option': {
        fontSize: '14px',
      },
      '.MuiPickersBasePicker-pickerView': {
        paddingBottom: '24px',
      },
      '#finicityConnectIframe': {
        '&&&': { zIndex: 10000 },
        height: '100vh',
        width: '100vw',
        inset: 0,
        position: 'fixed',
        background: '#000000cc',
      },
      '.intercom-app iframe[name="intercom-banner-frame"]': {
        zIndex: 2,
      },
      input: {
        // fix for iOS Safari zooming on focus
        [breakpoints.down('mobile')]: {
          '&:focus': {
            fontSize: 'inherit',
          },
        },
      },
      [`.${classNames.obtrusiveScrollbar} *`]: {
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          background: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '12px',
          background: '#c1c1c1',
          cursor: 'grab',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#b4b4b4',
        },
        '&::-webkit-scrollbar-thumb:active': {
          background: '#a8a8a8',
        },
      },
    })}
  />
)
